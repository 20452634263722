import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function BackgroundVideo({ src, poster, children }) {
  return (
    <Video src={src} poster={poster} autoPlay muted loop playsInline>
      {children}
    </Video>
  );
}

BackgroundVideo.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  children: PropTypes.node,
};

BackgroundVideo.defaultProps = {
  poster: '',
  children: null,
};

export default BackgroundVideo;
