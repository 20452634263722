import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0
    ${({ theme, nested }) =>
      nested ? theme.spacing.columnGutter * -1 : theme.spacing.columnGutter};
`;

export default Row;
