import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, darken } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { DrawerItem } from '../Menu';

import { colors } from '../../theme';

const TopItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${rem(18)};
  vertical-align: middle;
  margin-left: 0.5em;
  margin-top: -0.1em;
`;

const Toggle = styled.div`
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${(props) => props.theme.components.drawer.item.padding};
  padding-left: 0.5rem;
  border-left: 1px solid
    ${(props) => darken(0.1, props.theme.components.drawer.background)};

  &.active {
    ${Icon} {
      transform: rotate(180deg);
    }
  }
`;

const SubMenu = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  top: 100%;
  left: 0;
  background-color: ${(props) => props.theme.palette.background.content};
  min-width: ${rem(190)};
  font-size: 0.9em;
  margin-bottom: 1rem;

  /* visibility: hidden; */

  &.active {
    display: flex;
    /* visibility: visible; */
  }
`;

const SubItemLink = styled(DrawerItem)`
  text-transform: none;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
`;

const Root = styled.div`
  position: relative;

  .active {
    /* background: ${(props) => props.theme.palette.colors.greyLight}; */
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

function AccordionItem(props) {
  const [isActive, setActive] = useState(false);
  const { item, color, itemComponent: Item, childItemComponent } = props;
  const { children } = item;
  const ChildItem = childItemComponent || SubItemLink;

  function handleToggle() {
    setActive(!isActive);
  }

  return (
    <Root className={isActive && 'active'}>
      <TopItem className={isActive && 'active'}>
        <Item link={item.path} activeClassName="active" partiallyActive={false}>
          {item.label}
        </Item>
        {children?.length > 0 && (
          <Toggle className={isActive && 'active'}>
            <Icon
              icon={faChevronDown}
              color={colors.primary}
              onClick={handleToggle}
            />
          </Toggle>
        )}
      </TopItem>
      <SubMenu className={isActive && 'active'}>
        {children?.map((child) => {
          return (
            <ChildItem
              key={child.id}
              link={child.path}
              activeClassName="active"
              partiallyActive={false}
              color={color}
            >
              {child.label}
            </ChildItem>
          );
        })}
      </SubMenu>
    </Root>
  );
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  color: PropTypes.string,
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  childItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

AccordionItem.defaultProps = {
  color: 'default',
  itemComponent: DrawerItem,
  childItemComponent: SubItemLink,
};

export default AccordionItem;
