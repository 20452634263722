import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from '../../Link';

const Item = styled(Link)`
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${(props) => props.theme.components.drawer.item.padding};
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.item.colorNegative
      : theme.components.menu.item.color};
  font-weight: ${(props) => props.theme.components.menu.item.fontWeight};
  font-size: ${(props) => props.theme.components.drawer.item.fontSize};
  letter-spacing: ${(props) => props.theme.components.menu.item.letterSpacing};
  text-transform: ${(props) => props.theme.components.menu.item.textTransform};

  &:hover {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.item.hoverNegative
        : theme.components.menu.item.hover};
  }

  &.active {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

function DrawerItem({ link, children, color, ...other }) {
  return (
    <Item
      to={link}
      activeClassName="active"
      partiallyActive={false}
      color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Item>
  );
}

DrawerItem.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
};

DrawerItem.defaultProps = {
  color: 'default',
};

export default DrawerItem;
