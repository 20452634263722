import React from 'react';
import PropTypes from 'prop-types';
import { em, rem, lighten, getContrast } from 'polished';
import { StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
  faVimeo,
  faPinterest,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import Link from '../Link';

const Root = styled.div`
  display: block;
  width: 100%;
`;

const Label = styled.span`
  display: none;
  padding-left: 0.65em;
  line-height: 1;
  text-align: left;
  font-size: ${rem(15)};
  vertical-align: middle;
`;

const Item = styled(Link)`
  display: inline-block;
  line-height: ${em(34)};
  height: ${em(34)};
  width: ${em(34)};
  text-align: center;
  font-size: ${rem(17)};
  white-space: nowrap;
  border-radius: 999px;
  color: ${(props) => {
    const contrastRatio = getContrast(
      props.theme.palette.colors.primary,
      '#fff',
    );
    return contrastRatio > 2.1 ? '#fff' : '#fff';
  }};
  background-color: ${(props) => props.theme.palette.colors.primary};

  /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

  &:hover {
    background-color: ${(props) =>
      lighten(
        props.theme.components.button.colorMultiplier,
        props.theme.palette.colors.primary,
      )};
    color: ${(props) => {
      const contrastRatio = getContrast(
        props.theme.palette.colors.primary,
        '#fff',
      );
      return contrastRatio > 2.1 ? '#fff' : '#000';
    }};
  }

  ${({ variant }) =>
    variant === 'label' &&
    css`
      /* text-align: left; */
      width: 100%;
      padding: 0.25em;
      line-height: 1;

      ${Label} {
        display: inline-block;
      }
    `};
`;

const Icon = styled.span`
  display: inline-block;
  vertical-align: middle;
  min-width: 1em;
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        socialLinks {
          facebook
          instagram
          linkedin
          pinterest
          twitter
          vimeo
          youtube
        }
      }
    }
  }
`;

function SocialLinks({ items, variant }) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({
        site: {
          siteMetadata: { socialLinks: socialLinkData },
        },
      }) => {
        const socialLinks = {
          facebook: {
            name: 'Facebook',
            icon: <FontAwesomeIcon icon={faFacebookF} />,
            url: socialLinkData?.facebook,
          },
          twitter: {
            name: 'Twitter',
            icon: <FontAwesomeIcon icon={faTwitter} />,
            url: socialLinkData?.twitter,
          },
          instagram: {
            name: 'Instagram',
            icon: <FontAwesomeIcon icon={faInstagram} />,
            url: socialLinkData?.instagram,
          },
          linkedin: {
            name: 'Linkedin',
            icon: <FontAwesomeIcon icon={faLinkedin} />,
            url: socialLinkData?.linkedin,
          },
          vimeo: {
            name: 'Vimeo',
            icon: <FontAwesomeIcon icon={faVimeo} />,
            url: socialLinkData?.vimeo,
          },
          pinterest: {
            name: 'Pinterest',
            icon: <FontAwesomeIcon icon={faPinterest} />,
            url: socialLinkData?.pinterest,
          },
          youtube: {
            name: 'YouTube',
            icon: <FontAwesomeIcon icon={faYoutube} />,
            url: socialLinkData?.youtube,
          },
        };

        return (
          <Root>
            {items.map((key) => {
              const item = socialLinks[key];
              return (
                item.url && (
                  <Item
                    key={key}
                    to={item.url}
                    title={item.name}
                    variant={variant}
                  >
                    <Icon>{item.icon}</Icon>
                    {variant === 'label' && <Label>{item.name}</Label>}
                  </Item>
                )
              );
            })}
          </Root>
        );
      }}
    </StaticQuery>
  );
}

SocialLinks.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['label', 'icon']),
};

SocialLinks.defaultProps = {
  items: ['facebook', 'instagram', 'linkedin', 'youtube', 'pinterest', 'vimeo'],
  variant: 'icon',
};

export default SocialLinks;
