import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import logo from '../../images/logo/logo.svg';
import logoNeg from '../../images/logo/logo-w.svg';

// const LogoWrap = styled.div`
//   display: block;
//   width: ${(props) => props.theme.grid.maxWidth};
//   padding: 0 1rem 1.5rem;
// `;

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function SiteLogo({ negative, ...other }) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site }) => (
        <Logo
          src={negative ? logoNeg : logo}
          alt={site.siteMetadata.title}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        />
      )}
    </StaticQuery>
  );
}

SiteLogo.propTypes = {
  negative: PropTypes.bool,
};

SiteLogo.defaultProps = {
  negative: false,
};

export default SiteLogo;
