import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getContrast, lighten } from 'polished';

import Link from '../Link';

import { buttonVariants } from '../../theme';

// import LinkUtil from '../../utils/link';

const Root = styled(Link)`
  display: inline-block;
  cursor: pointer;
  border-radius: ${(props) => props.theme.components.button.radius};
  padding: ${(props) => props.theme.components.button.padding};
  appearance: none;
  user-select: none;
  text-align: center;
  font-size: ${(props) => props.theme.typography.button.buttonSizes.default};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.button.fontWeight};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  will-change: box-shadow background transform;
  transition: ${(props) =>
    `box-shadow ${props.theme.global.transition.duration} ${props.theme.global.transition.timing} background ${props.theme.global.transition.duration} ${props.theme.global.transition.timing} transform ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};

  ${({ expanded }) =>
    expanded &&
    css`
      width: 100%;
    `}

  &:hover {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
  }

  ${({ variant }) => {
    switch (variant) {
      case 'hollow':
        return css`
          border: 2px solid ${(props) => props.theme.palette.colors.primary};
          color: ${(props) => {
            const contrastRatio = getContrast(
              props.theme.palette.background.content,
              '#fff',
            );
            return contrastRatio > 2.1 ? '#fff' : '#000';
          }};
          background-color: transparent;

          /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

          &:hover {
            background-color: ${(props) => props.theme.palette.colors.primary};
            color: ${(props) => {
              const contrastRatio = getContrast(
                props.theme.palette.colors.primary,
                '#fff',
              );
              return contrastRatio > 2.1 ? '#fff' : '#000';
            }};
          }

          ${buttonVariants('hollow')}

          &:focus {
            outline: none;
          }

          &:disabled {
            background: gray;
          }
        `;
      default:
        return css`
          border: none;
          color: ${(props) => {
            const contrastRatio = getContrast(
              props.theme.palette.colors.primary,
              '#fff',
            );
            return contrastRatio > 2.1 ? '#fff' : '#fff';
          }};
          background-color: ${(props) => props.theme.palette.colors.primary};

          /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

          &:hover {
            background-color: ${(props) =>
              lighten(
                props.theme.components.button.colorMultiplier,
                props.theme.palette.colors.primary,
              )};
            color: ${(props) => {
              const contrastRatio = getContrast(
                props.theme.palette.colors.primary,
                '#fff',
              );
              return contrastRatio > 2.1 ? '#fff' : '#000';
            }};
          }

          ${buttonVariants('solid')}

          &:focus {
            outline: none;
          }

          &:disabled {
            background: gray;
          }
        `;
    }
  }}
`;

function Button({ children, link, variant, expanded, ...other }) {
  return (
    <Root
      variant={variant}
      to={link}
      as={typeof link === 'undefined' && 'div'}
      expanded={expanded}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Root>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(['fill', 'hollow']),
  expanded: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'fill',
  link: undefined,
  expanded: null,
};

export default Button;
