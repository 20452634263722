import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { colors } from '../../theme';

import { NavbarItem } from '../Menu';

const Root = styled.div`
  /* z-index: 1; */

  &.active {
    position: relative;

    /* z-index: 2; */
  }
`;

const ChildItemLink = styled(NavbarItem)`
  font-size: ${rem(15.5)};
  padding: ${rem(8.3)} ${rem(12)};
  line-height: 1.25;
  /* text-transform: none; */
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  background-color: ${(props) => props.theme.palette.background.dark};
  min-width: ${rem(190)};
  border-radius: 0 0 5px 5px;

  ${({ align }) => (align === 'end' ? `right: 0;` : `left: 0;`)}

  visibility: hidden;

  &.active {
    padding: ${rem(7)} 0;
    visibility: visible;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${rem(18)};
  vertical-align: middle;
  margin-left: 0.5em;
  margin-top: -0.1em;
`;

function DropdownItem(props) {
  const [isActive, setActive] = useState(false);
  const { item, color, itemComponent: Item, childItemComponent, align } = props;
  const { children } = item;
  const hasChildren = children?.length > 0;
  const ChildItem = childItemComponent || ChildItemLink;

  function handleEnter() {
    setActive(true);
  }

  function handleLeave() {
    setActive(false);
  }

  return (
    <Root
      className={isActive && 'active'}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <Item
        className={isActive && 'active'}
        link={item.path}
        activeClassName="active"
        partiallyActive={false}
        color={color}
      >
        {item.label}
        {hasChildren && <Icon icon={faChevronDown} color={colors.primary} />}
      </Item>
      {hasChildren && (
        <SubMenu className={isActive && 'active'} align={align}>
          {children?.map((child) => {
            return (
              <ChildItem
                key={child.id}
                link={child.path}
                activeClassName="active"
                partiallyActive={false}
                // color={color}
                color="negative"
              >
                {child.label}
              </ChildItem>
            );
          })}
        </SubMenu>
      )}
    </Root>
  );
}

DropdownItem.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  item: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  childItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

DropdownItem.defaultProps = {
  align: 'start',
  color: 'default',
  itemComponent: NavbarItem,
  childItemComponent: ChildItemLink,
};

export default DropdownItem;
