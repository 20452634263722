import { useTranslation } from 'react-i18next';

import svFlag from '../images/flags/se.svg';
import gbFlag from '../images/flags/gb.svg';

function getLanguages() {
  const { t } = useTranslation();

  return [
    {
      code: 'en',
      label: 'EN',
      name: t('english'),
      image: gbFlag,
    },
    {
      code: 'sv',
      label: 'SV',
      name: t('swedish'),
      image: svFlag,
    },
  ];
}

function getLanguage(langCode) {
  const languages = getLanguages();

  return languages.filter((lang) => lang.code === langCode)?.[0];
}

export { getLanguage, getLanguages };
