import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownItem from './DropdownItem';
import { NavbarItem } from '../Menu';

const Root = styled.nav`
  display: flex;
  align-items: center;
  flex-flow: wrap;

  ${({ align }) => {
    let alignment = '';

    switch (align) {
      case 'center':
        alignment = 'center';
        break;
      case 'end':
        alignment = 'flex-end';
        break;
      case 'start':
      default:
        alignment = 'flex-start';
        break;
    }

    return `justify-content: ${alignment};`;
  }}
`;

function DropdownMenu(props) {
  const { items, align, itemComponent: Item, color, ...other } = props;
  return (
    <Root align={align}>
      {items?.map((item, index) => {
        return (
          <DropdownItem
            key={item.id}
            item={item}
            itemComponent={Item}
            color={color}
            align={
              index + 1 === items.length && align === 'end' ? 'end' : 'start' // To prevent last item sub menu to overflow screen width, make menu appear from right
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
          />
        );
      })}
    </Root>
  );
}

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  align: PropTypes.oneOf(['start', 'center', 'end']),
  color: PropTypes.oneOf(['default', 'negative']),
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  childItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

DropdownMenu.defaultProps = {
  align: 'start',
  color: 'default',
  itemComponent: NavbarItem,
  childItemComponent: null,
};

export default DropdownMenu;
