import React from 'react'; //  useState, useEffect // useRef,
import { getImage, getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { em, rem, linearGradient, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Element, scroller } from 'react-scroll';
import { BgImage } from 'gbimage-bridge';

import BackgroundVideo from '../BackgroundVideo';
import SiteLogo from '../SiteLogo';
import Link from '../Link';

import { breakpoint, colors } from '../../theme';

const Wrap = styled(BgImage)`
  position: relative;
  background: ${(props) => {
    const { primary, secondary } = props.theme.palette.colors;
    return linearGradient({
      colorStops: [secondary, primary],
      toDirection: '180deg',
      fallback: props.theme.palette.background.body,
    });
  }};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  position: relative;
  display: table;
  width: 100%;
  text-align: center;
  min-height: ${rem(200)};

  ${breakpoint('medium')} {
    min-height: ${rem(280)};
  }

  ${breakpoint('mediumlarge')} {
    min-height: ${rem(380)};
  }

  ${({ big }) =>
    big &&
    `height: ${rem(420)};

    ${breakpoint('mediumlarge')} {
      height: ${rem(560)};
    }
  
    ${breakpoint('large')} {
      height: 100vh; /* height: 82vh; */
    }`}

  ${({ tint }) =>
    tint &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: ${(props) =>
          rgba(props.theme.palette.colors.black, 0.45)};
      }
    `}
`;

const Inner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Content = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto;
  color: ${(props) => props.theme.palette.colors.white};
  padding: ${({ theme, scrollDown }) =>
    `${rem(75)} ${theme.spacing.gutter} ${scrollDown ? rem(80) : rem(45)}`};
  font-size: ${rem(17)};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(18)};
  }

  ${breakpoint('large')} {
    padding-top: ${rem(100)};
    padding-bottom: ${rem(60)};
  }

  p {
    margin: 0.5rem 0 0 0;
  }
`;

const Children = styled.div`
  position: relative;
  margin: ${rem(16)} auto 0;
  max-width: ${rem(620)};
`;

const Actions = styled.div`
  width: 100%;
  margin: 1.5rem auto 0;
`;

const HeroHeading = styled.h2`
  margin: 0;
  /* font-weight: ${(props) => props.theme.typography.weights.heading.bold}; */
  color: ${(props) => props.theme.palette.colors.white};

  ${({ big }) =>
    big
      ? css`
          font-size: ${rem(40)};

          ${breakpoint('medium')} {
            font-size: ${rem(46)};
          }

          ${breakpoint('mediumlarge')} {
            font-size: ${rem(54)};
          }

          ${breakpoint('large')} {
            font-size: ${rem(64)};
          }
        `
      : css`
          font-size: ${rem(30)};

          ${breakpoint('medium')} {
            font-size: ${rem(40)};
          }

          ${breakpoint('mediumlarge')} {
            font-size: ${rem(50)};
          }

          ${breakpoint('large')} {
            font-size: ${rem(54)};
          }
        `}

  strong,
  b {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const PreHeading = styled.h3`
  margin: 0 0 ${rem(16)} 0;
  font-size: ${rem(14)};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.colors.white};
  opacity: 0.75;
  letter-spacing: 0.07em;
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(16)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }
`;

const Logo = styled(Link)`
  display: block;
  margin: ${rem(-15)} auto ${rem(22)};
  width: ${rem(80)};

  ${breakpoint('mediumlarge')} {
    width: ${rem(90)};
  }

  ${breakpoint('large')} {
    width: ${rem(98)};
  }
`;

const ScrollToButton = styled.button`
  position: absolute;
  bottom: ${rem(30)};
  left: 50%;
  transform: translateX(-50%);
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 999px;
  height: ${em(35)};
  width: ${em(35)};
  font-size: ${rem(16)};
  color: #fff;
  margin: 0 auto;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1em;
  color: inherit;
`;

const query = graphql`
  {
    fileName: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 75, layout: FULL_WIDTH)
      }
    }
  }
`;

function Hero({
  heading,
  preHeading,
  children,
  actions,
  big,
  tint,
  image,
  pageTitle,
  useImageFallback,
  logo,
  scrollDown,
  videoProps,
  animated,
}) {
  function scrollToBottom() {
    scroller.scrollTo('heroBottom', {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -70,
    });
  }
  // const heroRef = useRef(null);
  const salProps = {
    'data-sal': 'fade',
    'data-sal-duration': '450',
    'data-sal-delay': '650',
    'data-sal-easing': 'easeOutExpo',
  };
  // const [heroSize, setHeroSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // const handleResize = () => {
  //   setHeroSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ fileName: fallbackBackground }) => {
        const heroImage = getImage(
          image || (useImageFallback && fallbackBackground),
        );
        const heroImageSrc = getSrc(image || fallbackBackground);

        return (
          <Wrap Tag="div" as={!useImageFallback && 'div'} image={heroImage}>
            {typeof videoProps.src !== 'undefined' && (
              <BackgroundVideo
                poster={heroImageSrc}
                // containerWidth={heroSize.width}
                // containerHeight={heroSize.height}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...videoProps}
              />
            )}
            <Container
              id="heroContainer"
              big={big}
              tint={tint}
              // ref={heroRef}
            >
              <Inner>
                <Content
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(animated ? salProps : undefined)}
                  scrollDown={scrollDown}
                >
                  {logo && (
                    <Logo to="/">
                      <SiteLogo negative />
                    </Logo>
                  )}
                  {preHeading && <PreHeading>{preHeading}</PreHeading>}
                  {heading && (
                    <HeroHeading as={pageTitle && 'h1'} big={big}>
                      {heading}
                    </HeroHeading>
                  )}
                  {children && <Children>{children}</Children>}
                  {actions && <Actions>{actions}</Actions>}
                </Content>
              </Inner>
            </Container>
            {scrollDown && (
              <ScrollToButton type="button" onClick={() => scrollToBottom()}>
                <Icon icon={faChevronDown} color={colors.primary} />
              </ScrollToButton>
            )}
            <Element name="heroBottom" />
          </Wrap>
        );
      }}
    </StaticQuery>
  );
}

Hero.propTypes = {
  heading: PropTypes.node,
  preHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
  actions: PropTypes.node,
  big: PropTypes.bool,
  tint: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pageTitle: PropTypes.bool,
  useImageFallback: PropTypes.bool,
  logo: PropTypes.bool,
  videoProps: PropTypes.shape({
    src: PropTypes.string,
  }),
  scrollDown: PropTypes.bool,
  animated: PropTypes.bool,
};

Hero.defaultProps = {
  heading: '',
  children: '',
  actions: '',
  preHeading: '',
  big: false,
  tint: false,
  image: null,
  pageTitle: false,
  useImageFallback: true,
  logo: false,
  videoProps: {},
  scrollDown: true,
  animated: false,
};

export default Hero;
