import React, { useState } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { breakpoint } from '../../theme';

const Form = styled.form`
  width: 100%;

  input[type='email'],
  input[type='text'] {
    border: 0;
  }

  label {
    font-size: ${rem(15)};

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(14)};
    }
  }

  .newsletterConsent {
    position: relative;
    padding-left: 1.65rem;
    margin: 1rem 0;
    line-height: 1.5;
    font-size: ${rem(12)} !important;
    font-family: ${(props) => props.theme.typography.fonts.alt} !important;
    font-weight: ${(props) =>
      props.theme.typography.weights.alt.normal} !important;

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(11)};
    }

    input {
      position: absolute;
      top: ${rem(6)};
      left: 0;
      margin: 0;
    }
  }
`;

const Message = styled.div`
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.palette.colors.greyLight};

  p {
    margin: 0;
  }
`;

function NewsletterWidget() {
  const [isValid, setValid] = useState(false);
  const [message, setMessage] = useState('');

  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputConsent, setInputConsent] = useState(false);
  const listIds = ['8bbf2036-339c-4ae9-8558-b119da20588f'];

  const { t } = useTranslation();

  function validateType(type, value) {
    let regEx = '';

    if (type === 'email') {
      regEx =
        // eslint-disable-next-line no-useless-escape
        /^(([a-zA-Z0-9_\-\+]+)|([a-zA-Z0-9_\-\+]+)([a-zA-Z0-9_\-\+\.]*)([a-zA-Z0-9_\-\+]+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,63}|[0-9]{1,63})$/;
    }

    if (type === 'tel') {
      // eslint-disable-next-line no-useless-escape
      regEx = /^((\+|00)\d{1,3})\d{2,4}[\-]?(\d{3,14})$/;
    }

    return regEx.test(value);
  }

  const submitForm = (event) => {
    event.preventDefault();

    if (validateType('email', inputEmail) && !!inputName && !!inputConsent) {
      setValid(true);

      const subscription = {
        ListIds: listIds,
        Contact: {
          Email: inputEmail,
          Name: inputName,
        },
        ConsentText: t('newsletterConsent'),
      };

      const xhr = new XMLHttpRequest();

      xhr.open(
        'POST',
        'https://ui.ungpd.com/Api/Subscriptions/84bea666-0513-4ae8-b2f8-35b0cf094fbe/ajax',
        true,
      );

      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.send(JSON.stringify(subscription));

      setMessage(t('newsletterSuccess'));
    } else {
      setValid(false);

      setMessage(t('newsletterError'));
    }
  };

  return (
    <Form className={isValid && 'isValid'}>
      {message && (
        <Message>
          <p>{message}</p>
        </Message>
      )}
      <label htmlFor="contactName">{t('inputName')}:</label>
      <input
        type="text"
        name="Contact[Name]"
        id="contactName"
        placeholder={t('inputNamePlaceholder')}
        required
        onChange={(event) => setInputName(event.target.value)}
      />
      <label htmlFor="contactEmail">{t('inputEmail')}:</label>
      <input
        type="email"
        name="Contact[Email]"
        id="contactEmail"
        placeholder={t('inputEmailPlaceholder')}
        required
        onChange={(event) => setInputEmail(event.target.value)}
      />
      <label htmlFor="contactConsentText" className="newsletterConsent">
        <input
          type="checkbox"
          required
          name="ConsentText"
          id="contactConsentText"
          value={inputConsent}
          onChange={() => setInputConsent(!inputConsent)}
        />
        {t('newsletterConsent')}
      </label>
      <button type="submit" onClick={submitForm}>
        {t('newsletterSubmit')}
      </button>
    </Form>
  );
}

export default NewsletterWidget;
