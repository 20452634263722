import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { em } from 'polished';

import Link from '../../Link';

const Item = styled(Link)`
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.item.colorNegative
      : theme.components.menu.item.color};
  font-weight: ${(props) => props.theme.components.menu.item.fontWeight};
  letter-spacing: ${(props) => props.theme.components.menu.item.letterSpacing};
  text-transform: none;
  padding: 0.5rem 0.85rem;
  font-size: ${em(16)};

  /* This fixes dropdown icon FOUC */
  display: flex;
  white-space: nowrap;
  align-items: center;

  &:hover {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.item.hoverNegative
        : theme.components.menu.item.hover};
  }

  &.active {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.item.hoverNegative
        : theme.components.menu.item.hover};
  }
`;

function NavbarSubItem({ link, children, color, ...other }) {
  return (
    <Item
      to={link}
      activeClassName="active"
      partiallyActive={false}
      color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Item>
  );
}

NavbarSubItem.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
};

NavbarSubItem.defaultProps = {
  color: 'default',
};

export default NavbarSubItem;
