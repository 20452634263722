import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavbarItem from './Items/NavbarItem';
import NavbarItemVertical from './Items/NavbarItemVertical';
import NavbarSubItem from './Items/NavbarSubItem';
import DiscreteItem from './Items/DiscreteItem';
import DrawerItem from './Items/DrawerItem';

const Root = styled.nav`
  ${({ direction }) =>
    direction === 'horizontal'
      ? `
      display: flex;
      align-items: center;
      flex-flow: wrap;
    `
      : `
      display: flex;
      flex-direction: column;
  `}

  ${({ align }) => {
    let alignment = '';

    switch (align) {
      case 'center':
        alignment = 'center';
        break;
      case 'end':
        alignment = 'flex-end';
        break;
      case 'start':
      default:
        alignment = 'flex-start';
        break;
    }

    return `justify-content: ${alignment};`;
  }}
`;

function Menu({ items, children, align, direction, itemComponent: Item }) {
  return (
    <Root align={align} direction={direction}>
      {items?.map((item) => (
        <Item
          key={item.id}
          link={item.path}
          activeClassName="active"
          partiallyActive={false}
          // style={itemStyle}
        >
          {item.label}
        </Item>
      ))}
      {children}
    </Root>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  align: PropTypes.oneOf(['start', 'center', 'end']),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Menu.defaultProps = {
  children: '',
  align: 'start',
  direction: 'horizontal',
  itemComponent: NavbarItem,
};

export default Menu;

export {
  NavbarItem,
  NavbarItemVertical,
  DiscreteItem,
  NavbarSubItem,
  DrawerItem,
};
