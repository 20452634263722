import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import Link from '../../Link';

const Item = styled(Link)`
  font-size: ${rem(16)};
  font-family: ${(props) => props.theme.typography.fonts.alt};
  font-weight: ${(props) => props.theme.typography.weights.alt.normal};
  color: ${(props) => props.theme.typography.anchor.default};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  border-bottom: 1px solid ${(props) => props.theme.typography.anchor.default};
  margin-right: ${rem(20)};
  padding: ${rem(5)} ${rem(2)};

  &.active,
  &:hover {
    color: ${(props) => props.theme.typography.anchor.hover};
    border-color: ${(props) => props.theme.typography.anchor.hover};
  }
`;

function DiscreteItem({ link, children, color, ...other }) {
  return (
    <Item
      to={link}
      activeClassName="active"
      partiallyActive={false}
      color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Item>
  );
}

DiscreteItem.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
};

DiscreteItem.defaultProps = {
  color: 'default',
  link: null,
};

export default DiscreteItem;
