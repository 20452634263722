import { langs } from './i18n-config';

/*
 *
 * App constants
 *
 */

export const MAPS_API_KEY = '';
export const MULTILINGUAL = langs.length > 1;
