import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AccordionItem from './AccordionItem';
import { DrawerItem } from '../Menu';

const Root = styled.nav`
  /* display: flex;
  align-items: center;
  flex-flow: wrap; */
`;

function AccordionMenu({ items, color, itemComponent: Item, ...other }) {
  return (
    <Root>
      {items?.map((item) => {
        return (
          <AccordionItem
            key={item.id}
            item={item}
            itemComponent={Item}
            color={color}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
          />
        );
      })}
    </Root>
  );
}

AccordionMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  childItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

AccordionMenu.defaultProps = {
  color: 'default',
  itemComponent: DrawerItem,
  childItemComponent: null,
};

export default AccordionMenu;
